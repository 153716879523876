<template>
  <v-container>
    <h1>{{ $t("navbar.faq.title") }}</h1>
    <span>{{ $t("navbar.faq.subtitle") }}</span>

    <div v-if="content != null">
      <v-expansion-panels flat class="faq-list mt-2" accordion>
        <v-expansion-panel
          class="rounded mt-3"
          v-for="item in content"
          :key="item.id"
        >
          <v-expansion-panel-header>
            <p class="my-2">
              <strong v-html="item.title.rendered"></strong>
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.content.rendered"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-btn
      :title="$t('navbar.faq.backBtn')"
      class="back-button mt-6"
      large
      color="secondary"
      depressed
      to="/"
    >
      {{ $t("navbar.faq.backBtn") }}
    </v-btn>
  </v-container>
</template>
<script>
import CMService from "~/service/cmService";

export default {
  name: "FaqList",
  data() {
    return {
      content: null,
      pageData: null
    };
  },
  mounted() {
    this.fetchFaqList();
  },
  methods: {
    async fetchFaqList() {
      const res = await CMService.getCustomPostByFilters("faq", [
        {
          filterName: "filter[faq]"
        }
      ]);

      if (res && res.data && res.data.length) {
        this.content = res.data;
      }
    }
  },
  metaInfo() {
    return {
      title: "Le domande più frequenti",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Hai dubbi o quesiti riguardo al nostro servizio di spesa online Tosano? Cerca la risposta tra le domande poste più di frequente"
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "IT_it"
        },
        {
          property: "og:title",
          content: "Le domande più frequenti"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:site_name",
          content: "Tosano"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "La Tua Spesa - Tosano"
        },
        {
          name: "twitter:title",
          content: "Le domande più frequenti"
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  },
  jsonld() {
    if (this.content) {
      const items = this.content.map(item => ({
        "@type": "Question",
        name: item.title.rendered,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.content.rendered
        }
      }));
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: items
      };
    }
  }
};
</script>
<style scoped>
img.size-full {
  width: 100%;
}
.v-expansion-panel-header {
  padding: 16px 28px;
  font-size: 16px;
}
.v-expansion-panel-content__wrap {
  padding: 0 28px 16px;
}
</style>
